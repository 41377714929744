import React, { useState } from "react";
// import { styled } from "@mui/material/styles";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Link,  } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
// import { TiArrowSortedDown } from "react-icons/ti";
// import { TiArrowSortedUp } from "react-icons/ti";
// import { GoChevronDown } from "react-icons/go";
// import { GoChevronUp } from "react-icons/go";
// // import { CardHeader } from "@mui/material";
// import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
// import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import '../brokers.css';
import ConfirmDialog from './ConfirmDialog';
// import IPOStatusComponent from "./IPOStatusComponent";
import FaqComponent from './FaqComponent'; 
import { hostInfo , token } from '../server';
import { useEffect } from 'react';

function Brokers({deviceInfo,navigation}) {

  const [allBrokers, setAllBrokers] = useState([]);
  useEffect(() => {
    fetch(`${hostInfo}/all_brokers_details`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setAllBrokers(data);})
  }, []);
  // const faqData = [
  //   {
  //     question: "What role does a broker play in an IPO?",
  //     answer: "A broker acts as an intermediary between the investor and the stock exchange, facilitating the purchase of shares during an IPO. Brokers also provide advice, manage orders, and handle the allocation of shares."
  //   },
  //   {
  //     question: "How do brokers allocate shares in an IPO?",
  //     answer: "Brokers allocate shares in an IPO based on the number of shares available, demand from investors, and the allocation policy set by the issuer. Retail investors are often allocated shares through a lottery system if demand exceeds supply."
  //   },
  //   {
  //     question: "What fees do brokers charge for IPO transactions?",
  //     answer: "Brokers typically charge a commission or transaction fee for IPO trades, which can vary based on the broker's fee structure. Some brokers may also charge additional fees for services like providing research or managing IPO applications."
  //   },
  //   {
  //     question: "How can brokers help clients apply for IPO shares?",
  //     answer: "Brokers assist clients in applying for IPO shares by providing detailed information about the offering, helping with the application process, and ensuring that applications are submitted correctly and on time."
  //   },
  //   {
  //     question: "What information do brokers need from clients to apply for an IPO?",
  //     answer: "Brokers require clients to provide their PAN (Permanent Account Number), bank account details, and Demat account information. Clients may also need to specify the number of shares they wish to apply for and the price range."
  //   },
  //   {
  //     question: "Can brokers guarantee IPO share allocation to their clients?",
  //     answer: "No, brokers cannot guarantee IPO share allocation due to the high demand and limited supply. Allocation is often determined through a lottery system, especially for retail investors, and is subject to the issuer's allocation policy."
  //   },
  //   {
  //     question: "How do brokers handle refunds if an IPO application is not fully allotted?",
  //     answer: "If an IPO application is not fully allotted, brokers facilitate the refund process by ensuring that the unallocated funds are returned to the investor's bank account. This typically happens within a few days after the allotment."
  //   },
  //   {
  //     question: "What happens after an IPO share allocation is confirmed?",
  //     answer: "Once an IPO share allocation is confirmed, the allocated shares are credited to the investor's Demat account. The broker then provides updates on the listing date and any other relevant information for trading the shares."
  //   },
  //   {
  //     question: "How do brokers assist in the trading of newly listed IPO shares?",
  //     answer: "Brokers assist in trading newly listed IPO shares by providing market insights, executing buy or sell orders, and offering advice on trading strategies based on market conditions and the stock's performance."
  //   },
  //   {
  //     question: "What compliance requirements must brokers adhere to during an IPO?",
  //     answer: "Brokers must adhere to various compliance requirements during an IPO, including KYC (Know Your Customer) norms, ensuring transparency in share allocation, and following SEBI (Securities and Exchange Board of India) regulations."
  //   },
  //   {
  //     question: "How do brokers manage risk for clients investing in IPOs?",
  //     answer: "Brokers manage risk by advising clients on diversification, setting appropriate investment limits, and providing detailed analysis of the IPO and market conditions. They may also recommend hedging strategies to mitigate potential losses."
  //   },
  //   {
  //     question: "Can brokers offer margin funding for IPO applications?",
  //     answer: "Yes, some brokers offer margin funding for IPO applications, allowing investors to apply for shares with a smaller upfront investment. However, this involves borrowing funds, and investors should be aware of the associated risks and costs."
  //   },
  //   {
  //     question: "How do brokers provide research and analysis on upcoming IPOs?",
  //     answer: "Brokers provide research and analysis on upcoming IPOs through detailed reports, webinars, and one-on-one consultations. They analyze the company's financials, industry outlook, and potential risks to help investors make informed decisions."
  //   },
  //   {
  //     question: "What are the advantages of using a broker for IPO investments?",
  //     answer: "Using a broker for IPO investments offers several advantages, including expert guidance, seamless application processing, access to research and analysis, and the ability to execute trades efficiently once the IPO is listed."
  //   },
  //   {
  //     question: "How can brokers help clients understand the risks associated with IPOs?",
  //     answer: "Brokers help clients understand the risks associated with IPOs by explaining market volatility, the potential for price fluctuations after listing, and the lack of a historical trading record. They also provide strategies for mitigating these risks."
  //   }
  // ];
  
 console.log(allBrokers);
  // const [iconPills, setIconPills] = React.useState("1");
  // const [pills, setPills] = React.useState("1");
  // const [ipoType, setIpoType] = useState("All IPO");

  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  // const handleClick = (value) => {
  //     setExternalUrl(value);
  //     setDialogOpen(true);
  // };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  // const handleIpoTypeChange = (type) => {
  //   setIpoType(type);
  //   setActiveTab(type);
  //   setShowAllRows(false);
  // };

  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     backgroundColor: "#ff7847",
  //     color: theme.palette.common.white,
  //   },
  //   [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  //   },
  // }));

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  //   // // hide last border
  //   // "&:last-child td, &:last-child th": {
  //   //   border: 0,
  //   // },
  // }));

  // function formatDate(dateString) {
  //   const dateObject = new Date(dateString);
  //   const options = { month: "short", day: "2-digit", year: "numeric" };
  //   return dateObject.toLocaleDateString("en-US", options);
  // }


  // // Filter rows based on IPO type
  // const filteredRows =
  //   ipoType === "All IPO"
  //     ? currentIPOs
  //     : currentIPOs.filter((row) => {
  //         if (ipoType === "Mainboard IPO") {
  //           const lastValue = row[8] && row[8].split(" ").pop();
  //           return lastValue === "NSE";
  //         } else if (ipoType === "SME IPO") {
  //           const lastValue = row[8] && row[8].split(" ").pop();
  //           return lastValue === "SME";
  //         }
  //       });

  // const [showAllRows, setShowAllRows] = useState(false);
  // const [activeTab, setActiveTab] = useState("All IPO");
  // const initialRowCount = 6;

  // const visibleRows = showAllRows ? filteredRows.length : initialRowCount;

  const openExternal = (url) => {
    window.open(url, '_blank');
  };
  
  const handleDivClick = (url) => {
    // Redirect to another page when clicking outside the buttons
    window.location.href = url;
  };

  const handleButtonClick = (event, url) => {
    event.stopPropagation(); // Prevent the div's onClick from triggering
    if (url) {
      openExternal(url);
    }
  };

  function shuffleArray(array) {
    return array.slice().sort(() => Math.random() - 0.5);
  }
  // Filter the brokers where Broker_Banner is an empty string
  const filteredBrokers = allBrokers && allBrokers?.filter(broker => broker.Broker_Banner !== "");
  const shuffledBrokers = filteredBrokers && shuffleArray(filteredBrokers)?.slice(0, 4);

  return (
    <>
     <Helmet>
     <title>{`Top Stock Brokers in India ${new Date().getFullYear()} | Leading Experts in Stock Trading`}</title>
     <meta name="description" content={`Explore the top stock brokers in India for ${new Date().getFullYear()}. Discover expert services, investment opportunities, and the best brokerage firms for your trading needs.`} />
     <meta name="keywords" content={`Stock Brokers, Top Brokers India, Best Brokers ${new Date().getFullYear()}, Stock Trading, Investment Services, Brokerage Firms, Trading Experts, IPOtank, IPO tank`} />

     <meta property="og:url" content="https://www.ipotank.com/brokers" /> <meta property="og:type" content="website" /> <meta property="og:title" content={`Top Stock Brokers in India ${new Date().getFullYear()} | Leading Experts in Stock Trading`} /> <meta property="og:description" content={`Explore the top stock brokers in India for ${new Date().getFullYear()}. Discover expert services, investment opportunities, and the best brokerage firms for your trading needs.`} />
    </Helmet>
    {/* backgroundImage: "url('../best8.jfif')",backgroundSize: "cover", backgroundRepeat: "no-repeat", */}
      <div style={{ padding:"10px 0 10px 0",background:"linear-gradient(90deg, rgba(251,196,150,1) 0%, rgba(196,199,199,1) 52%, rgba(255,255,255,1) 100%)"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
           <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
           {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        {/* <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link> */}
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        <Link to={`/brokers`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>Brokers</p>
        </Link>
      </div>)}
      <br/>
    
      <h1 style={{marginTop:"-20px"}}>Brokers</h1>
      <p style={{marginTop:"-10px", textAlign: "justify",fontSize:"15px"}}>Explore the top stock brokers in India for 2024. Discover expert services, investment opportunities, and the best brokerage firms for your trading needs.</p>
      {/* <h4 style={{marginTop:"10px"}}>A "Current IPO" refers to an Initial Public Offering that is currently open for subscription or recently closed but not yet listed on a stock exchange. It represents a pivotal moment for a private company transitioning to a publicly traded entity, offering shares to investors for the first time to raise capital.</h4> */}
      {/* <p style={{marginTop:"0px", textAlign: "justify",fontSize:"15px"}}>List of all current IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Stay updated on the latest IPOs and their specifics in one place.</p> */}
      </div>
      </div>
      </div>
      <br/>
      {/* <br/> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

{allBrokers && allBrokers?.length > 0 ? ( <>
           <div style={{ width: '93%', maxWidth: '1200px' ,  display: "flex",
          flexDirection: "row",flexWrap:"wrap",gap:"20px" }}>
      
     <div style={{ 
         width:"100%",maxWidth:"900px",minWidth:"250px",
        // width:"auto",
         height:"auto",display:"flex",flexDirection:"column",gap:"15px"}}>
     
    {/* {allBrokers && allBrokers.length > 0 ? ( <> */}
    {allBrokers?.map((broker) => (
      <div key={broker.id} className="card-body gap-3">
      <Link to={`/broker/${broker.Broker_Name?.toLowerCase().replace(" ","-")}`} style={{textDecoration:"none",color:"black"}}>
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src={broker.Broker_logo}
            alt={broker.Broker_Name}
            width="100%"
            height="auto"
            loading="lazy"
          />
        </div>
        <h4 className="ipo_name px-1" >{broker.Broker_Name}</h4>
      </div></Link>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1" onClick={()=>handleDivClick(`/broker/${broker.Broker_Name?.toLowerCase().replace(" ","-")}`)}>
        {/* <Link to="/broker/zerodha" style={{textDecoration:"none"}}> */}
        {/* {broker.Product_Basket} */}
        {JSON.parse(broker?.Product_Basket).map((i)=> 
          <span className="badge_span" data-role={i}>
            <i className="fa fa-check-circle" aria-hidden="true"></i> {i}
          </span>)}
          
         
          <a onClick={(event) => handleButtonClick(event)}  className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href={`/broker/${broker.Broker_Name?.toLowerCase().replace(" ","-")}`}>
            View Details
          </a>
          <button
            // type="button"
            className="btn filledPrimaryBtn align-self-center me-2"
            onClick={(event) => handleButtonClick(event, broker?.Open_Demat_Account_Url)}
            // onClick={() => openExternal("https://bit.ly/37jC9Ef")}
          >
            Open Account
          </button>
        </div>
        <Link to={`/broker/${broker.Broker_Name?.toLowerCase().replace(" ","-")}`} style={{textDecoration:"none",color:"black"}}>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">
            <p className="label">Account Opening</p>
            <span data-role="charges">{broker.Account_Opening}</span>
          </div>
          <div className="data_box">
            <p className="label">Account Maintenance</p>
            <span data-role="charges">{broker.Account_Maintenance}</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Delivery</p>
            <span data-role="charges">{broker.Equity_Delivery}</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Intraday</p>
            <span data-role="charges">{broker.Equity_Intraday}</span>
          </div>
        </div></Link>
      </div>
    </div> ))}
    {/* </>):(<div className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>)} */}
     {/* <div className="card-body gap-3">
      <Link to={`/broker/}`} style={{textDecoration:"none",color:"black"}}>
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src="https://media.ipoji.com/broker/images/zerodha-logo.jpeg"
            alt="Zerodha"
            width="100%"
            height="auto"
            loading="lazy"
          />
        </div>
        <h4 className="ipo_name px-1" >Zerodha</h4>
      </div></Link>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1" onClick={()=>handleDivClick("/broker/zerodha")}>
      
          <span className="badge_span" data-role="Equity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Equity
          </span>
          <span className="badge_span" data-role="Commodity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Commodity
          </span>
          <span className="badge_span" data-role="Currency">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Currency
          </span>
          <span className="badge_span" data-role="Futures">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Futures
          </span>
          <span className="badge_span" data-role="Options">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Options
          </span>
         
          <a onClick={(event) => handleButtonClick(event)}  className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href="/broker/zerodha">
            View Details
          </a>
          <button
            // type="button"
            className="btn filledPrimaryBtn align-self-center me-2"
            onClick={(event) => handleButtonClick(event, 'https://bit.ly/37jC9Ef')}
            // onClick={() => openExternal("https://bit.ly/37jC9Ef")}
          >
            Open Account
          </button>
        </div>
        <Link to="/broker/zerodha" style={{textDecoration:"none",color:"black"}}>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">
            <p className="label">Account Opening</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Account Maintenance</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Delivery</p>
            <span data-role="charges">Zero</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Intraday</p>
            <span data-role="charges">0.03%</span>
          </div>
        </div></Link>
      </div>
    </div> */}


    {/* <div className="card-body gap-3">
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src="https://media.ipoji.com/broker/images/kotak-sec-logo.jpeg"
            alt="Zerodha"
            width="100%"
            height="auto"
            loading="lazy"
          />
        </div>
        <h4 className="ipo_name px-1" role="heading">Espresso Sharekhan</h4>
      </div>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1">
          <span className="badge_span" data-role="Equity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Equity
          </span>
          <span className="badge_span" data-role="Commodity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Commodity
          </span>
          <span className="badge_span" data-role="Currency">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Currency
          </span>
        
          <a role="link" className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href="/broker/zerodha">
            View Details
          </a>
          <button
            role="button"
            className="btn filledPrimaryBtn align-self-center me-2"
            onClick={() => openExternal("https://bit.ly/37jC9Ef")}
          >
            Open Account
          </button>
        </div>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">
            <p className="label">Account Opening</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Account Maintenance</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Delivery</p>
            <span data-role="charges">Zero</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Intraday</p>
            <span data-role="charges">0.03%</span>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body gap-3">
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src="https://media.ipoji.com/broker/images/dhan-logo.jpeg"
            alt="Zerodha"
            width="100%"
            height="auto"
            loading="lazy"
          />
        </div>
        <h4 className="ipo_name px-1" role="heading">Zerodha</h4>
      </div>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1">
          <span className="badge_span" data-role="Equity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Equity
          </span>
        
          <a role="link" className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href="/broker/zerodha">
            View Details
          </a>
          <button
            role="button"
            className="btn filledPrimaryBtn align-self-center me-2"
            onClick={() => openExternal("https://bit.ly/37jC9Ef")}
          >
            Open Account
          </button>
        </div>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">
            <p className="label">Account Opening</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Account Maintenance</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Delivery</p>
            <span data-role="charges">Zero</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Intraday</p>
            <span data-role="charges">0.03%</span>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body gap-3">
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src="https://media.ipoji.com/broker/images/angel-logo.jpeg"
            alt="Zerodha"
            width="100%"
            height="auto"
            loading="lazy"
          />
        </div>
        <h4 className="ipo_name px-1" role="heading">Espresso Sharekhan</h4>
      </div>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1">
          <span className="badge_span" data-role="Equity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Equity
          </span>
          <span className="badge_span" data-role="Commodity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Commodity
          </span>
          <span className="badge_span" data-role="Currency">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Currency
          </span>
        
          <a role="link" className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href="/broker/zerodha">
            View Details
          </a>
          <button
            role="button"
            className="btn filledPrimaryBtn align-self-center me-2"
            onClick={() => openExternal("https://bit.ly/37jC9Ef")}
          >
            Open Account
          </button>
        </div>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">
            <p className="label">Account Opening</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Account Maintenance</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Delivery</p>
            <span data-role="charges">Zero</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Intraday</p>
            <span data-role="charges">0.03%</span>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body gap-3">
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src="https://media.ipoji.com/broker/images/zerodha-logo.jpeg"
            alt="Zerodha"
            width="100%"
            height="auto"
            loading="lazy"
          />
        </div>
        <h4 className="ipo_name px-1" role="heading">Espresso Sharekhan</h4>
      </div>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1">
          <span className="badge_span" data-role="Equity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Equity
          </span>
          <span className="badge_span" data-role="Commodity">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Commodity
          </span>
          <span className="badge_span" data-role="Currency">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Currency
          </span>
        
          <a role="link" className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href="/broker/zerodha">
            View Details
          </a>
          <button
            role="button"
            className="btn filledPrimaryBtn align-self-center me-2"
            onClick={() => openExternal("https://bit.ly/37jC9Ef")}
          >
            Open Account
          </button>
        </div>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">
            <p className="label">Account Opening</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Account Maintenance</p>
            <span data-role="charges">₹300</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Delivery</p>
            <span data-role="charges">Zero</span>
          </div>
          <div className="data_box">
            <p className="label">Equity Intraday</p>
            <span data-role="charges">0.03%</span>
          </div>
        </div>
      </div>
    </div>
    */}

    
      </div>

      <div style={{ 
    //    minWidth:"200px",
    width:"auto",
    height:"auto" }}>
   <div  className="sideBoxContainer">
   {shuffledBrokers?.map((broker) => (
    <a className="sideBox fadeUp" 
       href={broker.Open_Demat_Account_Url} 
       target="_blank" 
       title={broker.Broker_Name} 
       rel="noopener noreferrer" >
       
      <p className="cardTitle" style={{textDecoration:"none",color:"black",marginBottom:"10px"}}>{broker.Broker_Name}</p>
      <img 
        src={broker.Broker_Banner} 
        alt={broker.Broker_Name} 
        className="cardImage" 
        loading="lazy" 
        style={{marginBottom:"15px"}}
      />
      <button 
        className="btn knowMoreBtn" 
        // role="button" 
        type="button">
        Know More
      </button>
    </a>))}
    {/* <a className="sideBox fadeUp" 
       href="https://bit.ly/37jC9Ef" 
       target="_blank" 
       title="Zerodha" 
       rel="noopener noreferrer">
       
      <p role="heading" className="cardTitle">Zerodha</p>
      <img 
        src="https://media.ipoji.com/tab-offer-app/images/Zerodha_offer.png" 
        alt="Zerodha" 
        className="cardImage" 
        loading="lazy" 
      />
      <button 
        className="btn knowMoreBtn" 
        role="button" 
        type="button">
        Know More
      </button>
    </a>
    <a className="sideBox fadeUp" 
       href="https://bit.ly/37jC9Ef" 
       target="_blank" 
       title="Zerodha" 
       rel="noopener noreferrer">
       
      <p role="heading" className="cardTitle">Zerodha</p>
      <img 
        src="https://media.ipoji.com/tab-offer-app/images/Zerodha_offer.png" 
        alt="Zerodha" 
        className="cardImage" 
        loading="lazy" 
      />
      <button 
        className="btn knowMoreBtn" 
        role="button" 
        type="button">
        Know More
      </button>
    </a>
    <a className="sideBox fadeUp" 
       href="https://bit.ly/37jC9Ef" 
       target="_blank" 
       title="Zerodha" 
       rel="noopener noreferrer">
       
      <p role="heading" className="cardTitle">Zerodha</p>
      <img 
        src="https://media.ipoji.com/tab-offer-app/images/Zerodha_offer.png" 
        alt="Zerodha" 
        className="cardImage" 
        loading="lazy" 
      />
      <button 
        className="btn knowMoreBtn" 
        role="button" 
        type="button">
        Know More
      </button>
    </a>
    <a className="sideBox fadeUp" 
       href="https://bit.ly/37jC9Ef" 
       target="_blank" 
       title="Zerodha" 
       rel="noopener noreferrer">
       
      <p role="heading" className="cardTitle">Zerodha</p>
      <img 
        src="https://media.ipoji.com/tab-offer-app/images/Zerodha_offer.png" 
        alt="Zerodha" 
        className="cardImage" 
        loading="lazy" 
      />
      <button 
        className="btn knowMoreBtn" 
        role="button" 
        type="button">
        Know More
      </button>
    </a> */}
    </div>
     
   

      


     
      </div>

      
      </div>
      </>):(<div className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>)}

      <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
        <br/>
        </div>

        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "93%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="Brokers" /></div></div>
     
    </>
  );
}

export default Brokers;
