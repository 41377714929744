// // import * as React from 'react';
// // function IPOGMP({allIPOsGMP}) {
// //   console.log(allIPOsGMP);
  
// //     return (
// //     <div><br/>
// //     <br/>
// //     <br/>hello IPOGMP</div>
// //     );
// //   }
// //   export default IPOGMP;

// import React, { useMemo } from 'react';
// import { useTable, useGlobalFilter, usePagination } from 'react-table';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
// import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed and set up

// // Global Filter Component
// const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
//   <TextField
//     value={globalFilter || ''}
//     onChange={(e) => setGlobalFilter(e.target.value)}
//     placeholder="Search Issuer Company"
//     variant="outlined"
//     margin="normal"
//     fullWidth
//   />
// );

// function IPOGMP({ allIPOsGMP }) {
//   console.log(allIPOsGMP);
//   const data = useMemo(() => allIPOsGMP, [allIPOsGMP]);

//   const columns = useMemo(() => [
//     {
//       Header: 'Issuer Company',
//       accessor: 'IssuerCompany',
//       Cell: ({ value }) => (
//         <Link to={`/ipo/${value.toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: 'bold', color: '#666',textDecoration:"none" }}>
//           {value}
//         </Link>
//       )
//     },
//     { Header: 'GMP Date', accessor: 'GMP_Date' },
//     { Header: 'IPO Price', accessor: 'IPO_Price' },
//     {
//       Header: 'GMP',
//       accessor: 'GMP',
//       Cell: ({ value }) => (
//         <span style={{ color: value < 0 ? 'red' : 'inherit',fontWeight:"bold" }}>
//           {value}
//         </span>
//       )
//     },
//     { Header: 'Sub2 Sauda Rate', accessor: 'Sub2_Sauda_Rate' },
//     { Header: 'Expected Listing Gain', accessor: 'Expected_Listing_Gain' },
//     { Header: 'Last Updated', accessor: 'Last_Updated' },
//   ], []);

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     state,
//     setGlobalFilter,
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state: { pageIndex, pageSize, globalFilter }
//   } = useTable(
//     { columns, data },
//     useGlobalFilter,
//     usePagination
//   );

//   return (
//     <>
//       <Helmet>
//         <title>Privacy Policy - Protecting Your Information at IPOtank</title>
//         <meta name="description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website." />
//         <meta name="keywords" content="Privacy Policy, personal information protection, data privacy, GDPR, CCPA, privacy practices" />
//       </Helmet>

//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           fontSize: "14px",
//           marginTop: "30px",
//           marginBottom: "30px",
//           textAlign: "justify",
//         }}
//       >
//         <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
//           <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
//           <TableContainer component={Paper}>
//             <Table {...getTableProps()}>
//               <TableHead style={{ background: "linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%)" }}>
//                 {headerGroups.map(headerGroup => (
//                   <TableRow {...headerGroup.getHeaderGroupProps()}>
//                     {headerGroup.headers.map(column => (
//                       <TableCell {...column.getHeaderProps()} style={{ color: "white", fontSize: "15px", borderRight: "1px solid #ddd" }}>
//                         {column.render('Header')}
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//               </TableHead>
//               <TableBody {...getTableBodyProps()}>
//                 {page.map(row => {
//                   prepareRow(row);
//                   // const issuerCompany = row.original.IssuerCompany;
//                   return (
//                     // <Link to={`/ipo/${issuerCompany.toLowerCase().replace(/ /g, '-')}`}  style={{ textDecoration:"none" }}>
//                     <TableRow {...row.getRowProps()}>
//                       {row.cells.map(cell => (
//                         <TableCell {...cell.getCellProps()}>
//                           {cell.render('Cell')}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                     // </Link>/
//                   );
//                 })}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <TablePagination
//             component="div"
//             count={rows.length}
//             page={pageIndex}
//             onPageChange={(event, newPage) => gotoPage(newPage)}
//             rowsPerPage={pageSize}
//             onRowsPerPageChange={e => setPageSize(Number(e.target.value))}
//             rowsPerPageOptions={[10, 20, 30]}
//             nextIconButtonProps={{ disabled: !canNextPage }}
//             backIconButtonProps={{ disabled: !canPreviousPage }}
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// export default IPOGMP;

import React, { useMemo, useState } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate, Link } from 'react-router-dom';
import '../IPOGMP.css'; // Make sure you have this CSS file
import { SlArrowRight } from "react-icons/sl";
import FaqComponent from './FaqComponent'; 
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { hostInfo , token } from '../server';
import { useEffect } from 'react';
// Global Filter Component
// const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
//   <TextField
//     value={globalFilter || ''}
//     onChange={(e) => setGlobalFilter(e.target.value)}
//     placeholder="Search Issuer Company"
//     variant="outlined"
//     margin="normal"
//     fullWidth
//   />
// );

// const GlobalFilter = ({ globalFilter, setGlobalFilter, options }) => (
//   <Autocomplete
//     value={globalFilter || null}
//     onChange={(event, newValue) => setGlobalFilter(newValue)}
//     inputValue={globalFilter || ''}
//     onInputChange={(event, newInputValue) => setGlobalFilter(newInputValue)}
//     options={options} // Array of options for the autocomplete
//     renderInput={(params) => (
//       <TextField
//         {...params}
//         placeholder="Search Issuer Company"
//         variant="outlined"
//         margin="normal"
//         fullWidth
//         InputProps={{
//           ...params.InputProps,
//           startAdornment: (
//             <InputAdornment position="start">
//               <SearchIcon />
//             </InputAdornment>
//           ),
//         }}
//       />
//     )}
//   />
// );

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <TextField
    value={globalFilter || ''}
    onChange={(e) => setGlobalFilter(e.target.value)}
    placeholder="Search Issuer Company"
    variant="outlined"
    margin="normal"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      style: { height: 40, fontSize: 14 }, // Adjust height and font size
    }}
    style={{ maxWidth: 500 }} // Control overall width
  />
);


function IPOGMP({ deviceInfo,navigation }) {
  
  const [allIPOsGMP, setAllIPOsGMP] = useState([]);
 
  useEffect(() => {
    fetch(`${hostInfo}/gmp`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setAllIPOsGMP(data);})
  }, []);

  // const faqData = [
  //   {
  //     question: "What is IPO Grey Market Premium (GMP)?",
  //     answer: "IPO Grey Market Premium (GMP) refers to the extra amount that investors are willing to pay for shares of an IPO in an unofficial or grey market before they are officially listed on the stock exchange."
  //   },
  //   {
  //     question: "How is IPO GMP determined?",
  //     answer: "IPO GMP is determined by demand and supply in the grey market, investor sentiment, and expectations of the IPO's performance upon listing. It's not regulated and can fluctuate widely based on market perceptions."
  //   },
  //   {
  //     question: "Why is IPO GMP important?",
  //     answer: "IPO GMP is often seen as an indicator of how an IPO might perform on its listing day. A high GMP suggests strong demand and potentially higher listing gains, while a low or negative GMP might indicate weaker demand."
  //   },
  //   {
  //     question: "Is IPO GMP a reliable predictor of listing day performance?",
  //     answer: "While IPO GMP can give an indication of market sentiment, it is not always a reliable predictor of listing day performance. Market conditions, company fundamentals, and broader economic factors can all influence the actual listing price."
  //   },
  //   {
  //     question: "Can I trade IPO shares in the grey market?",
  //     answer: "Yes, IPO shares can be traded in the grey market, but this is done unofficially and is not regulated by stock exchanges. Trading in the grey market carries higher risks, including the possibility of losing your investment."
  //   },
  //   {
  //     question: "How can I find the current GMP of an IPO?",
  //     answer: "The current GMP of an IPO is usually shared by financial websites, brokers, and market analysts. It's important to verify the sources and understand that GMP can vary depending on the source and the timing of the report."
  //   },
  //   {
  //     question: "What factors influence the GMP of an IPO?",
  //     answer: "Several factors influence the GMP of an IPO, including the company's reputation, financial performance, investor demand, market trends, and the overall economic environment. Speculation and rumors can also impact GMP."
  //   },
  //   {
  //     question: "Should I invest in an IPO based on its GMP?",
  //     answer: "Investing in an IPO solely based on its GMP is risky. While a high GMP may indicate positive sentiment, it's crucial to consider the company's fundamentals, long-term potential, and your investment strategy before making a decision."
  //   },
  //   {
  //     question: "What happens if the actual listing price is lower than the GMP?",
  //     answer: "If the actual listing price is lower than the GMP, investors who bought shares in the grey market may incur losses. The official market price can be affected by a variety of factors that differ from those influencing the grey market."
  //   },
  //   {
  //     question: "Is trading in the grey market legal?",
  //     answer: "Trading in the grey market is not illegal, but it is not regulated by any official stock exchange. This means there is no legal recourse if something goes wrong, and it involves higher risks compared to trading on the official exchange."
  //   },
  //   {
  //     question: "How can I minimize risks when considering IPO GMP?",
  //     answer: "To minimize risks, consider the IPO's fundamentals, use GMP as just one of many indicators, and avoid over-relying on grey market trends. Diversifying your investments and consulting with a financial advisor can also help mitigate risks."
  //   },
  //   {
  //     question: "Can IPO GMP change after the IPO closes?",
  //     answer: "Yes, IPO GMP can change even after the IPO subscription closes, as it continues to reflect market sentiment up until the listing day. Market developments, news, and broader economic factors can influence GMP during this period."
  //   },
  //   {
  //     question: "What is the difference between IPO GMP and listing gains?",
  //     answer: "IPO GMP refers to the premium in the grey market before listing, while listing gains refer to the actual profit made when the IPO shares are sold on the stock exchange at a price higher than the issue price."
  //   },
  //   {
  //     question: "Does IPO GMP affect the allotment of shares?",
  //     answer: "No, IPO GMP does not affect the allotment of shares. Share allotment is based on the subscription levels and the issuer's allocation policy, while GMP reflects market sentiment and expected demand."
  //   },
  //   {
  //     question: "What are the risks of relying on IPO GMP for investment decisions?",
  //     answer: "Relying solely on IPO GMP for investment decisions is risky as GMP is speculative and not always accurate. The actual market performance can differ, and investing without considering the company's fundamentals can lead to potential losses."
  //   }
  // ];

  
  console.log(allIPOsGMP);
  const navigate = useNavigate();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  const data = useMemo(() => allIPOsGMP, [allIPOsGMP]);

  const columns = useMemo(() => [
    {
      Header: 'Issuer Company',
      accessor: 'IssuerCompany',
      Cell: ({ value, row }) => (
        <Link
          to={`/ipo/${value.toLowerCase().replace(/ /g, '-')}`}
          className={`issuer-link ${clickedRowIndex === row.index ? 'clicked' : ''}`}
        >
          {value}
        </Link>
      )
    },
    { Header: 'IPO Type', accessor: 'Exchange',  Cell: ({ value }) => (
      // <span>
        <div  style={{color:"white",fontSize:"11px"}}>{value && value.split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid #7aa7db",borderRadius:"5px",backgroundColor:"#7aa7db",textDecoration:"none"}}>SME</label> :<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid #6cb456",borderRadius:"5px",backgroundColor:"#6cb456",textDecoration:"none"}}>Mainline</label>}</div>
      // </span>
    ) },
    { Header: 'GMP Date', accessor: 'GMP_Date' },
    { Header: 'IPO Price', accessor: 'IPO_Price' },
    {
      Header: 'GMP',
      accessor: 'GMP',
      Cell: ({ value }) => (
        <span style={{ color: value < 0 ? 'red' : 'green', fontWeight: "bold" }}>
          {value}
        </span>
      )
    },
    { Header: 'Sub2 Sauda Rate', accessor: 'Sub2_Sauda_Rate' },
    { Header: 'Expected Listing Gain', accessor: 'Expected_Listing_Gain',  Cell: ({ value }) => (
      <span style={{ color: value.split("(")[1].replace(")","").replace("%","") < 0 ? 'red' : 'green' }}>
        {value}
      </span>
    ) },
    // { Header: 'Last Updated', accessor: 'Last_Updated' },
  ], [clickedRowIndex]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    { columns, data, initialState: { pageSize: 20 } },
    useGlobalFilter,
    usePagination
  );

  const handleRowClick = (rowIndex, row) => {
    setClickedRowIndex(row.index);
    navigate(`/ipo/${row.original.IssuerCompany.toLowerCase().replace(/ /g, '-')}`);
  };

  return (
    <>
      {/* <Helmet>
        <title>Privacy Policy - Protecting Your Information at IPOtank</title>
        <meta name="description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website." />
        <meta name="keywords" content="Privacy Policy, personal information protection, data privacy, GDPR, CCPA, privacy practices" />
      </Helmet> */}
      <Helmet>
      <title>IPO GMP - Latest Grey Market Premium Details at IPOtank</title>
      <meta name="description" content="Latest Grey Market Premium (GMP) for IPOs at IPO tank. Stay updated with information on issuer companies, GMP dates, IPO prices, and expected listing gains." />
      <meta name="keywords" content="IPO GMP, Grey Market Premium, IPO data, GMP data, IPO prices, listing gains, financial data, IPOtank, IPO tank" />
        {/* <meta name="author" content="IPOtank Team" /> */}

        <meta property="og:url" content="https://www.ipotank.com/ipo-gmp" /> <meta property="og:type" content="website" /> <meta property="og:title" content="IPO GMP - Latest Grey Market Premium Details at IPOtank" /> <meta property="og:description" content="Latest Grey Market Premium (GMP) for IPOs at IPO tank. Stay updated with information on issuer companies, GMP dates, IPO prices, and expected listing gains." />
      </Helmet>

      <div style={{ background:"linear-gradient(90deg, rgba(251,196,150,1) 0%, rgba(196,199,199,1) 52%, rgba(255,255,255,1) 100%)", padding: "10px 0 10px 0" }}>
      
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
        {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left', display: "flex", alignItems: "left", gap: "15px", color: "#666", paddingTop: "25px" }} >
            <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }} >
              <p>Home</p>
            </Link>
            <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
            <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
              <p>IPO</p>
            </Link>
            <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
            <Link to={`/ipo-gmp`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
              <p>GMP</p>
            </Link>
          </div>)}
          <br />
          {/* Title and Description */}
          <h1 style={{ marginTop: "-20px" }}>IPO GMP</h1>
          {/* <h3 style={{ marginTop: "30px" }}>The financial markets are on the move</h3> */}
          <p style={{ marginTop: "-10px", textAlign: "justify",fontSize:"15px"}}>Discover the latest Grey Market Premium (GMP) data for IPOs at IPOtank. Stay informed about issuer companies, GMP dates, IPO prices, and expected listing gains to make better investment decisions.</p>
          <p style={{ marginTop: "-5px", textAlign: "justify",fontSize:"15px"}}>*GMP provided in this app is derived from market rumors. It is not a guaranteed indicator of the actual listing price and should be interpreted with caution.</p>
        </div>
      </div>
    </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          // marginTop: "10px",
          // marginBottom: "10px",
          textAlign: "justify",
        }}
      >
        <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          <TableContainer component={Paper}>
            <Table {...getTableProps()}>
              <TableHead style={{ background: "linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%)" }}>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <TableCell {...column.getHeaderProps()} style={{ color: "white", fontSize: "15px" }}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => handleRowClick(rowIndex, row)}
                      style={{ cursor: 'pointer' }}
                      className="table-row"
                    >
                      {row.cells.map(cell => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            page={pageIndex}
            onPageChange={(event, newPage) => gotoPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={e => setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 30]}
            nextIconButtonProps={{ disabled: !canNextPage }}
            backIconButtonProps={{ disabled: !canPreviousPage }}
          />
        </div>
      </div>

<br/>
<div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "90%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="GMP" /></div></div>

    </>
  );
}

export default IPOGMP;
