// import React, { useEffect, useState } from 'react';
// import '../ShareButton.css'; // Import the CSS file
// import { FaShareAlt } from 'react-icons/fa';
// import { IoShareSocialOutline } from "react-icons/io5";
// import { RiShareForwardFill } from "react-icons/ri";
// import { hostInfo } from '../server';

// const ShareButton = ({ ipoLink, companyname }) => {

 

//   const [ipoDetails, setIpoDetails] = useState({});
//   // console.log(ipoDetails,companyname,"..................")

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${hostInfo}/get_ipo_details_share`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({ company_name: companyname })
//         });
  
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
  
//         const data = await response.json();
//         // console.log(data)
//         setIpoDetails(data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         // Handle the error as needed
//       }
//     };
  
//     fetchData();

//   }, [companyname]);


//   const handleShare = async () => {
//     if (navigator.share) {
//       try {
//         await navigator.share({
//           title: 'Check out this IPO!',
//           url: ipoLink,
//         });
//         // console.log('Share successful');
//       } catch (error) {
//         console.error('Share failed', error);
//       }
//     } else {
//       // Fallback for browsers that don't support the Web Share API
//       window.open(`https://wa.me/?text=${encodeURIComponent(ipoLink)}`, '_blank');
//     }
//   };

//   return (
//     // <div className="share-icon" >
//     <div  className="share-icon" style={{display: "flex", alignItems: "center",marginLeft:"25px"}} onClick={handleShare}>
//       <IoShareSocialOutline  /><div style={{fontSize:"13px",marginLeft:"5px"}}>Share</div>
//     </div>
//     // <div style={{border:"1px solid #fdd0c0",borderRadius: "30px", width: "30px", height: "30px", backgroundColor: "#fdd0c0", display: "flex", alignItems: "center", justifyContent: "center"}}>
//     // <div style={{display: "flex", alignItems: "center",fontSize:"14px",gap:"5px"}}>
//     // <IoShareSocialOutline onClick={handleShare} />Share</div>
//     // {/* </div> */}
//     // <button className="share-button" onClick={handleShare}>
//     //   <IoShareSocialOutline className="share-icon" /> Share
//     // </button>
//   );
// };

// export default ShareButton;



import React, { useEffect, useState } from 'react';
import '../ShareButton.css'; // Import the CSS file
import { IoShareSocialOutline } from "react-icons/io5";
import { hostInfo , token } from '../server';
// import ShareModal from './ShareModal';



const ShareButton = ({ ipoLink, companyname }) => {
  const [ipoDetails, setIpoDetails] = useState({});
  // console.log(ipoDetails);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${hostInfo}/get_ipo_details_share`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
            'Content-Type': 'application/json', // Set the Content-Type if necessary
          },
          body: JSON.stringify({ company_name: companyname })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setIpoDetails(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [companyname]);

   
  const pricePerShareString = ipoDetails?.IssuePrice;
  // console.log(pricePerShareString);
  let lastValue ;
  
  if (pricePerShareString) {
    const regexRange = /(\d+\.\d+) to (\d+\.\d+)/; // Regex to match price range
    const regexSingle = /^(\d+\.\d+)$/; // Regex to match single price
  
    const matchRange = pricePerShareString.match(regexRange);
    const matchSingle = pricePerShareString.match(regexSingle);
  
    if (matchRange) {
      const pricePerShareRange = [parseFloat(matchRange[1]), parseFloat(matchRange[2])];
      // console.log(pricePerShareRange);
      // pricePerShareRange1 = pricePerShareRange[pricePerShareRange.length - 1];
  
      // Accessing the last value only if pricePerShareRange is not null or undefined
      lastValue = pricePerShareRange[pricePerShareRange.length - 1] * parseInt(ipoDetails?.LotSize.replace(/,/g, ''), 10);
  
      // Safely accessing ipoDetails and its properties
      // console.log(lastValue);
    } else if (matchSingle) {
      const pricePerShareRange = [parseFloat(matchSingle[1])];
      // pricePerShareRange2 = pricePerShareRange[0];
  
      // Accessing the last value only if pricePerShareRange is not null or undefined
      lastValue = pricePerShareRange[0] * parseInt(ipoDetails?.LotSize.replace(/,/g, ''), 10);
  
      // Safely accessing ipoDetails and its properties
      // console.log(lastValue);
    } else {
      lastValue = '';
    }
  } else {
    lastValue = '';
    // console.log("Price per share string is undefined.");
  }
  
  // console.log('Last Value:', lastValue);
  // console.log('Price Per Share Range 1:', pricePerShareRange1);
  // console.log('Price Per Share Range 2:', pricePerShareRange2);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const message = `IPO Details
// Company Name: ${ipoDetails.IssuerCompany} ${ipoDetails?.Exchange?.split(" ").pop() === "SME" ? "(SME)" : "(Mainboard)"}
// Issue Date: ${formatDateString(ipoDetails.OpenDate)} - ${formatDateString(ipoDetails.CloseDate)}
// Listing Date: ${formatDateString(ipoDetails.ListingDate)}
// Issue Price: Rs.${ipoDetails.IssuePrice}
// Issue Size: ${ipoDetails.IssueSize} Cr.
// Lot Size: ${ipoDetails.LotSize} Shares
// Market Lot (Investment): ${ipoDetails.LotSize ? ipoDetails.LotSize : "---"} Shares (₹${lastValue.toLocaleString()}/-)
// Hey, I'm using IPOTank to get details and GMP of any IPO.
// Visit now: ${ipoLink}`;

//   const handleShare = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };
  

  const handleShare = async () => {
     const message = `
IPO Details
Company Name: ${ipoDetails.IssuerCompany} ${ipoDetails?.Exchange?.split(" ").pop() === "SME" ? "(SME)" : "(Mainboard)"}
Issue Date: ${formatDateString(ipoDetails.OpenDate)} - ${formatDateString(ipoDetails.CloseDate)}
Listing Date: ${formatDateString(ipoDetails.ListingDate)}
Issue Price: Rs.${ipoDetails.IssuePrice}
Issue Size: ${ipoDetails.IssueSize} Cr.
Lot Size: ${ipoDetails.LotSize} Shares
Market Lot (Investment): ${ipoDetails.LotSize ? ipoDetails.LotSize : "---"} Shares (₹${lastValue.toLocaleString()}/-)
Hey, I'm using IPOTank to get details and GMP of any IPO.
Visit now: ${ipoLink}
`;
  
    // console.log("Message to share:", message);
    // console.log("IPO Link:", ipoLink);
    // const simpleMessage = 'Check out this IPO on IPOTank: ' + ipoLink;
  
    // if (navigator.share) {
    //   try {
    //     await navigator.share({
    //       title: 'Check out this IPO!',
    //       text: "parth",
    //        url: ipoLink,
    //     });
    //     // console.log('Share successful');
    //   } catch (error) {
    //     console.error('Share failed', error);
    //   }
    // } 
    // else {
      // Ensure URL encoding for WhatsApp
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
      // console.log("WhatsApp URL:", whatsappUrl);
      window.open(whatsappUrl, '_blank');
    // }
  };
  

  // const handleShare = async () => {
   
  //   const message = `
  //   IPO Details
  //   Company Name: ${ipoDetails.IssuerCompany} ${ipoDetails.Exchange.split(" ").pop() === "SME" ? "(SME)" : "(Mainboard)"}
  //   Issue Date: ${formatDateString(ipoDetails.OpenDate)} - ${formatDateString(ipoDetails.CloseDate)}
  //   Listing Date: ${formatDateString(ipoDetails.ListingDate)}
  //   Issue Price: Rs.${ipoDetails.IssuePrice}
  //   Issue Size: ${ipoDetails.IssueSize} Cr.
  //   Lot Size: ${ipoDetails.LotSize} Shares
  //   Market Lot (Investment): ${ipoDetails.LotSize ? ipoDetails.LotSize : "---"} Shares (₹${lastValue.toLocaleString()}/-)\n
  //   Hey, I'm using IPOTank to get details and GMP of any IPO.
  //   Visit now: ${ipoLink}`;
    
  //   // console.log("Message to share:", message);
  //   // console.log("IPO Link:", ipoLink);
  
  //   if (navigator.share) {
  //     try {
  //       await navigator.share({
  //         title: 'Check out this IPO!',
  //         text: `
  //         IPO Details
  //         Company Name: ${ipoDetails.IssuerCompany} ${ipoDetails.Exchange.split(" ").pop() === "SME" ? "(SME)" : "(Mainboard)"}
  //         Issue Date: ${formatDateString(ipoDetails.OpenDate)} - ${formatDateString(ipoDetails.CloseDate)}
  //         Listing Date: ${formatDateString(ipoDetails.ListingDate)}
  //         Issue Price: Rs.${ipoDetails.IssuePrice}
  //         Issue Size: ${ipoDetails.IssueSize} Cr.
  //         Lot Size: ${ipoDetails.LotSize} Shares
  //         Market Lot (Investment): ${ipoDetails.LotSize ? ipoDetails.LotSize : "---"} Shares (₹${lastValue.toLocaleString()}/-)\n
  //         Hey, I'm using IPOTank to get details and GMP of any IPO.
  //         Visit now: ${ipoLink}`,
  //         url: ipoLink,
  //       });
  //       // console.log('Share successful');
  //     } catch (error) {
  //       console.error('Share failed', error);
  //     }
  //   } else {
  //     // Ensure URL encoding for WhatsApp
  //     const encodedMessage = encodeURIComponent(message);
  //     const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
  //     // console.log("WhatsApp URL:", whatsappUrl);
  //     window.open(whatsappUrl, '_blank');
  //   }
  // };


  return (
    <div className="share-icon" style={{ display: "flex", alignItems: "center" }} onClick={handleShare}>
      <IoShareSocialOutline />
      <div style={{ fontSize: "14px", marginLeft: "5px" }}>Share</div>
      {/* {isModalOpen && <ShareModal message={message} ipoLink={ipoLink} onClose={closeModal} />} */}
    </div>
  );
};

export default ShareButton;

