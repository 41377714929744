import React from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import CurrentIPOs from './components/CurrentIPOs';
import UpcomingIPOs from './components/UpcomingIPOs';
import RecentlyListedIPOs from './components/RecentlyListedIPOs';
import ClosedIPOs from './components/ClosedIPOs';
import IPONews from './components/IPONews';
// import IPOCalculator from './components/IPOCalculator';
import IPODetails from './components/IPODetails';
import IPOCalendar from './components/IPOCalendar';
import IPOPerformance from './components/IPOPerformance';
import { useState } from 'react';
import { useEffect } from 'react';
import ScrollToTopOnRouteChange from './components/ScrollToTopOnRouteChange';
import NewsDetails from './components/NewsDetails';
import BrokerDetails from './components/BrokerDetails';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import DisclaimerAndPrivacy from './components/DisclaimerAndPrivacy';
import AboutUs from './components/AboutUs';
import Brokers from './components/Brokers';
// import './App.css'
// import Layout from './components/Layout';
import { hostInfo , token } from './server';
import IPOGMP from './components/IPOGMP';
import BuybackDetails from './components/BuybackDetails';
import Buyback from './components/Buyback';
import BuybackCalendar from './components/BuybackCalendar';
import RightsIssues from './components/RightsIssues';
import RightsIssueDetails from './components/RightsIssueDetails';
import RightsIssueCalendar from './components/RightsIssueCalendar';
import TermsAndConditions from './components/TermsAndConditions';
import SupportAndFAQs from './components/SupportAndFAQs';
import UserAgentDetector from './components/UserAgentDetector';
import Bowser from 'bowser';  // Import Bowser library
import IPOInvestorCategories from './components/IPOInvestorCategories';
import config from './config.json'; 
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const checkDevice = (info) => {
  // Check if OS name is Android and version is 99
  if (info.os.name === "Android" && info.os.version === "99") {
      // Check platform type and if vendor is present
      if (info.platform.type === "mobile" && info.platform.vendor) {
          return "app"; // Return 'app' if all conditions are met
      }
  }
  return null; // Return null if conditions are not met
};

function App() {
  // const location = useLocation();
  // console.log(location?.pathname);


  // const location = useLocation(); // Get the current location from React Router

  // useEffect(() => {
  //   // Send a pageview event to Google Analytics when the route changes
  //   ReactGA.send({ hitType: "pageview", page: location.pathname });
  // }, [location]); // Re-run the effect whenever the route changes


  const [allIPOs, setAllIPOs] = useState([]);
  const [allIPOsGMP, setAllIPOsGMP] = useState([]);
  const [allIPOsPerformance, setAllIPOsPerformance] = useState([]);
  // const [IPOs, setIPOs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [currentIPOs, setCurrentIPOs] = useState([]);
  // console.log(allIPOs)
  const [closedIPOs, setClosedIPOs] = useState([]);
  const [upcomingIPOs, setUpcomingIPOs] = useState([]);
  // console.log(upcomingIPOs)
  const [recentlyListedIPOs, setRecentlyListedIPOs] = useState([]);
  console.log(recentlyListedIPOs)
  const [allBrokers, setAllBrokers] = useState([]);
  const [allBuybacks, setAllBuybacks] = useState([]);
  const [allRightsIssues, setAllRightsIssues] = useState([]);

  // useEffect(() => {
  //   fetch(`${hostInfo}/all_current_ipos`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setCurrentIPOs(data.current_ipos);})
   
  // }, []);


  // useEffect(() => {
  //   fetch(`${hostInfo}/all_closed_ipos`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setClosedIPOs(data.closed_ipos);})
   
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/all_upcoming_ipos`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setUpcomingIPOs(data.upcoming_ipos);})
   
  // }, []);


  
  // useEffect(() => {
  //   fetch(`${hostInfo}/all_recently_listed_ipos`, {
  //     method: 'POST', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     },
  //     body: JSON.stringify({ limit: 10 })
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setRecentlyListedIPOs(data.recently_listed_ipos);})
   
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/all_ipos`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setClosedIPOs(data.closed_ipos);setRecentlyListedIPOs(data.recently_listed_ipos);setUpcomingIPOs(data.upcoming_ipos)})
   
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/all_ipos_details`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setAllIPOs(data);})
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/all_brokers_details`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setAllBrokers(data);})
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/gmp`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setAllIPOsGMP(data);})
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/performance`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setAllIPOsPerformance(data);})
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/blogs`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setBlogs(data);})
  // }, []);

  // useEffect(() => {
  //   fetch(`${hostInfo}/all_buybacks_details`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setAllBuybacks(data);})
  // }, []);


  // useEffect(() => {
  //   fetch(`${hostInfo}/all_rights_issues_details`, {
  //     method: 'GET', // Specify the HTTP method
  //     headers: {
  //       'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
  //       'Content-Type': 'application/json', // Set the Content-Type if necessary
  //     }
  //   })
  //   .then(response => {
  //     // if (!response.ok) {
  //     //   throw new Error('Network response was not ok');
  //     // }
  //     return response.json();
  //   }).then((data)=>{setAllRightsIssues(data);})
  // }, []);
 
//   // const Layout = ({ children }) => (
//   //   // <div>
//   //   //   <Navbar />
//   //   //   <main>{children}</main>
//   //   //   <Footer />
//   //   // </div>
     
      
//   //     <>
//   //     <Navbar />
//   //     <ScrollToTopOnRouteChange>
//   //     <div className="App" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
//   //     <main>{children}</main>
//   //     <Footer />
//   //     </div>
//   //     </ScrollToTopOnRouteChange>
//   //     </>
    
//   // );

// //   const data={
// //     "about_section": "<p>Technocraft Industries India Limited, started with manufacturing of Drum Closures and became the second largest global manufacturer of Steel Drum closures including other products like seal plugs, gaskets, drum flanges etc.<br/><br/>The company also ventured into manufacturing of plastic closures for small metal and plastic containers and is a dominant player in the engineering sector, tube division and produces high precision scaffolding systems for building construction.<br/><br/>Technocraft Industries also diversified into manufacturing of Cotton Yarn and accorded a 100% EOU (Export Oriented Unit) status.</p>",
// //     "additional_text": "Tender Offer",
// //     "buyback_details": "{\"Security Name\": \"Technocraft Industries (india) Ltd.\", \"Buyback Price\": \"\₹4,500.00\", \"Face Value\": \"\₹10.00\", \"Listing at Group\": \"BSE, NSE\", \"Lead Manager\": \"Systematix Corporate Services Limited\", \"Buyback Docs\": \"https://www.bseindia.com/xml-data/corpfiling/AttachLive/93a32b6c-f500-4f96-b62d-34bd321dad9c.pdf\", \"Issue Size\": \"288,889 ( % of Total number of equity Shares)\"}",
// //     "buyback_price": "₹4,500.00",
// //     "buyback_ratio": "[{\"Category\": \"Reserved Category for Small Shareholders\", \"Entitlement Ratio of Buyback\": \"97/745\", \"Shares Offered\": \"43334\"}, {\"Category\": \"General Category for all other Eligible Shareholders\", \"Entitlement Ratio of Buyback\": \"6/553\", \"Shares Offered\": \"245555\"}]",
// //     "calculation_section": "{\"Calculation of Investment\": {\"Maximum market value of a share holder as on record date\": \"\₹200,000.00\", \"Buyback price per share\": \"\₹4,500.00\", \"Maximum share for small shareholders (200000/ 4500)\": \"44.44 share\", \"Closing price of stock as of 2024-08-13\": \"\₹3,266.81\", \"Maximum amount insted in buyback (\₹3,266.81 x 44.44444444444444 shares)\": \"\₹145,191.56\"}, \"Expected Profit from Buyback\": [{\"Acceptance Ratio\": \"Amount Invested in Buyback\", \"33%\": \"\₹145,191.56\", \"50%\": \"\₹145,191.56\", \"75%\": \"\₹145,191.56\", \"100%\": \"\₹145,191.56\"}, {\"Acceptance Ratio\": \"No. of Shares Buyback\", \"33%\": \"14\", \"50%\": \"22\", \"75%\": \"33\", \"100%\": \"44\"}, {\"Acceptance Ratio\": \"Buyback Profit\", \"33%\": \"17264\", \"50%\": \"27130\", \"75%\": \"40695\", \"100%\": \"54260\"}, {\"Acceptance Ratio\": \"Profit%\", \"33%\": \"11.89%\", \"50%\": \"18.69%\", \"75%\": \"28.03%\", \"100%\": \"37.37%\"}]}",
// //     "company_contact": "{\"Company Name\": \"Technocraft Industries (india) Ltd. Buyback\", \"Address\": \"Technocraft House, A-25, Road No. 03, MIDC Industrial Estate, Andheri (East), Mumbai - 400093\", \"Email\": \"investor@technocraftgroup.com\", \"Phone\": \"022-4098 2222\", \"Website\": \"https://www.technocraftgroup.com/\"}",
// //     "company_name": "Technocraft Industries Buyback 2024",
// //     "financials": "[{\"Period Ended\": \"Net Worth\", \"31-Mar-23\": \"--\", \"31-Mar-22\": \"--\", \"31-Mar-21\": \"--\"}, {\"Period Ended\": \"Total Assets\", \"31-Mar-23\": \"2,364.26\", \"31-Mar-22\": \"2,110.70\", \"31-Mar-21\": \"1,776.13\"}, {\"Period Ended\": \"Reserves And Surplus\", \"31-Mar-23\": \"1,155.92\", \"31-Mar-22\": \"1,147.65\", \"31-Mar-21\": \"928.08\"}, {\"Period Ended\": \"Total Revenue\", \"31-Mar-23\": \"2,034.90\", \"31-Mar-22\": \"1,958.55\", \"31-Mar-21\": \"1,352.34\"}, {\"Period Ended\": \"Total Borrowing\", \"31-Mar-23\": \"--\", \"31-Mar-22\": \"--\", \"31-Mar-21\": \"--\"}, {\"Period Ended\": \"Profit After Tax\", \"31-Mar-23\": \"278.28\", \"31-Mar-22\": \"274.45\", \"31-Mar-21\": \"146.83\"}]",
// //     "id": 4,
// //     "image_url": "https://media.ipoji.com/buyback/images/Tecnhocraft_Industries_logo.jpeg",
// //     "ipo_name": "Technocraft Industries Buyback 2024",
// //     "ipo_name_id": 1,
// //     "ipo_route_name": "technocraft-industries-buyback-2024",
// //     "ipo_short_name": "Technocraft Industries (india) Ltd. Buyback",
// //     "issue_timetable": "{\"Last Date to buy shares\": \"Aug 27, 2024\", \"Record Date\": \"Aug 26, 2024\", \"Offer Opens On\": \"Sep 2, 2024\", \"Offer Closes On *\": \"Sep 6, 2024\", \"Last Date for receipt of Tender Forms\": \"Sep 6, 2024\", \"Finalisation of Buyback Acceptance\": \"Sep 11, 2024\", \"Last Date for settlement of bids\": \"Sep 12, 2024\", \"Last Date for Extinguishment of Shares\": \"Sep 24, 2024\"}",
// //     "objective": "<p>The buyback will help the company to return the surplus cash to its members.<br/><br/>The buyback is expected to improve return on equity through cash distribution and earnings per share by reducing the equity base; thereby increasing the long-term shareholder's value.<br/><br/>The buyback gives an option to the shareholders holding equity shares of the company, either to sell their equity shares and receive cash or not to sell their equity shares and get a resultant increase in their percentage shareholding, post the buyback offer, without additional investment.<br/><br/>The buyback, which is being implemented through the tender offer as prescribed under the buyback regulations, would involve a minimum reservation of 15% for small shareholders and allocation of a higher number of shares as per their entitlement or 15% of the number of shares to be bought back, reserved for the small shareholders. The company believes that this reservation for small shareholders would benefit many public shareholders, who would be classified as small shareholders.</p>",
// //     "p_tags_with_i": "[\"* No. of shares stands to change according to the closing price on the record date\", \"* For simplicity, we have considered the price on date Aug 13, 2024\"]",
// //     "participating_section": "{\"heading\": \"How to Participate in Technocraft Industries (india) Ltd. Buyback?\", \"steps\": [\"1. Firstly, to be eligible for the buyback the investor should have shares of Technocraft Industries (india) Ltd. Buyback in Demat or physical form as on the record date Aug 26, 2024.\", \"2. Once you have shares in Demat, you can participate in the buyback process which is opening from Sep 2, 2024 by selling your shares through your broker on BSE, NSE.\", \"3. Then on Sep 24, 2024, the payment will be given to you for accepted shares and unaccepted shares will be returned to your Demat account.\"]}",
// //     "registrar_contact": "{\"Registrar Name\": \"Link Intime India Private Ltd\", \"Email\": \"technocraft.buyback2024@linkintime.co.in\", \"Phone\": \"+91-22-4918 6270\", \"Website\": \"https://linkintime.co.in/initial_offer/public-issues.html\"}"
// // };



const [deviceInfo, setDeviceInfo] = useState("");
console.log(deviceInfo);

useEffect(() => {
  // Use Bowser's parse() function to get user agent details

  const browserInfo = Bowser.parse(window.navigator.userAgent);
  // const browserInfo = {"browser": {"name": "Chrome", "version": "66.0.3359.126"}, "os": {"name": "Android", "version": "99"}, "platform": {"type": "mobile", "vendor": "Nexus"}, "engine": {"name": "Blink"}};
  console.log(browserInfo);
  // // Extract useful data
  // const browserName = browserInfo.browser.name || 'Unknown';
  // const browserVersion = browserInfo.browser.version || 'Unknown';
  // const osName = browserInfo.os.name || 'Unknown';
  // const osVersion = browserInfo.os.version || 'Unknown';
  // const platformType = browserInfo.platform.type || 'Unknown';  // mobile, tablet, desktop
  // const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  // // Determine if it's an iPad explicitly
  // const isIpad = navigator.platform === 'iPad' || 
  //                (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  // // Determine device type based on platform
  // let deviceType = 'Desktop';
  // if (isIpad) {
  //   deviceType = 'iPad';
  // } else if (platformType === 'mobile') {
  //   deviceType = 'Mobile';
  // } else if (platformType === 'tablet') {
  //   deviceType = 'Tablet';
  // } else {
  //   deviceType = platformType;
  // }

  // // Set the gathered information in the state
  // const deviceData = {
  //   deviceType,
  //   browser: browserName,
  //   browserVersion,
  //   os: osName,
  //   osVersion,
  //   platform: platformType,
  //   isTouchDevice,
  // };

 

// Call the function
const result = checkDevice(browserInfo);
console.log(result);

  setDeviceInfo(result);

  // Send the device data to the backend
  fetch(`${hostInfo}/logDeviceInfo`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(browserInfo),
  });
}, []);



  return (
    <>
   {/* <BrowserRouter> */}
      {/* <UserAgentDetector /> */}

   <Router>
   <div style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
   {/* <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Your Company" />
      </Helmet> */}
        <Navbar deviceInfo={deviceInfo} />
        <div style={{ paddingTop: '60px' }}> {/* Adjust paddingTop to match Navbar height */}
          <ScrollToTopOnRouteChange>
            <Routes>
             <Route path="/" element={<Home />} />
           <Route path="/current-ipo" element={<CurrentIPOs  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/upcoming-ipo" element={<UpcomingIPOs  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/recently-listed-ipo" element={<RecentlyListedIPOs  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/closed-ipo" element={<ClosedIPOs  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/ipo-news" element={<IPONews  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
         <Route path="/news/ipo/:ipo-news" element={<NewsDetails deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/ipo-gmp" element={<IPOGMP  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/ipo/:ipo-details" element={<IPODetails  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/ipo-calendar" element={<IPOCalendar  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/ipo-performance" element={<IPOPerformance  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
          
           <Route path="/ContactUs" element={<ContactUs  />} />
           <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
           {/* <Route path="/DisclaimerAndPrivacy" element={<DisclaimerAndPrivacy  />} /> */}
           <Route path="/AboutUs" element={<AboutUs />} />
                <Route path="/brokers" element={<Brokers deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
                <Route path="/broker/:broker-details" element={<BrokerDetails  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />

           {/* <Route path="/ipo/undefined" element={<Home currentIPOs={currentIPOs} closedIPOs={closedIPOs} upcomingIPOs={upcomingIPOs} recentlyListedIPOs={recentlyListedIPOs} allIPOsPerformance={allIPOsPerformance}/>} /> */}
           <Route path="/ipo" element={<Home deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/news/ipo" element={<IPONews  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/broker" element={<Brokers  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />

           <Route path="/buyback/:buyback-details" element={<BuybackDetails deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/buyback" element={<Buyback  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/buybacks" element={<Buyback  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/buyback-calendar" element={<BuybackCalendar  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           {/* <Route path="/news/ipo/undefined" element={<IPONews blogs={blogs} />} /> */}

           <Route path="/rights-issue/:rights-issue-details" element={<RightsIssueDetails deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/rights-issue" element={<RightsIssues  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/rights-issues" element={<RightsIssues  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           <Route path="/rights-issue-calendar" element={<RightsIssueCalendar  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />

           <Route path="/terms-conditions" element={<TermsAndConditions />} />
           <Route path="/support-faqs" element={<SupportAndFAQs />} />

           <Route path="/ipo-investor-categories" element={<IPOInvestorCategories />} />


             {/* Catch-all route for undefined paths */}
           <Route path="*" element={<Home  deviceInfo={deviceInfo} navigation={config.removeNavigationForMobileApp} />} />
           
            </Routes>         
          </ScrollToTopOnRouteChange>
          
        </div>
        <Footer />
      </div>
    </Router>
    {/* </BrowserRouter> */}
    </>
    // <Router>
    //   <ScrollToTopOnRouteChange>
    //   <div className="App" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
    //     <Navbar/>
    //     <Routes>
    //       <Route path="/" element={<Home currentIPOs={currentIPOs} closedIPOs={closedIPOs} upcomingIPOs={upcomingIPOs} recentlyListedIPOs={recentlyListedIPOs}/>} />
    //       <Route path="/current-ipo" element={<CurrentIPOs currentIPOs={currentIPOs}/>} />
    //       <Route path="/upcoming-ipo" element={<UpcomingIPOs upcomingIPOs={upcomingIPOs}/>} />
    //       <Route path="/recently-listed-ipo" element={<RecentlyListedIPOs recentlyListedIPOs={recentlyListedIPOs}/>} />
    //       <Route path="/closed-ipo" element={<ClosedIPOs closedIPOs={closedIPOs}/>} />
    //       <Route path="/ipo-news" element={<IPONews blogs={blogs} />} />
    //       <Route path="/news/ipo/:ipo-news" element={<NewsDetails/>} />
    //       <Route path="/ipo-calculator" element={<IPOCalculator/>} />
    //       <Route path="/ipo/:ipo-details" element={<IPODetails/>} />
    //       <Route path="/ipo-calendar" element={<IPOCalendar allIPOs={allIPOs} />} />
    //       <Route path="/top-gainer" element={<TopGainer/>} />
          
    //       <Route path="/ContactUs" element={<ContactUs  />} />
    //       <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
    //       <Route path="/DisclaimerAndPrivacy" element={<DisclaimerAndPrivacy  />} />
    //       <Route path="/AboutUs" element={<AboutUs />} />
    //     </Routes>
    //     <Footer />
    //   </div>
    //   </ScrollToTopOnRouteChange>
    // </Router>
  );
}

export default App;
